module.exports = [{
      plugin: require('/root/verifeco/frontend/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Verifeco","short_name":"Verifeco","start_url":"/","background_color":"black","theme_color":"black","display":"standalone","icon":"src/images/favicon.jpg"},
    },{
      plugin: require('/root/verifeco/frontend/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/root/verifeco/frontend/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
