import React, { createContext, useState } from 'react'

const initialState = {
    user: null,
    country: {},
}

export const AppContext = createContext({})

export default ({ children }) => {
    return (
        <AppContext.Provider value={useState(initialState)}>
            {children}
        </AppContext.Provider>
    )
}
