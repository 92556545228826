// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/root/verifeco/frontend/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/root/verifeco/frontend/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activate-js": () => import("/root/verifeco/frontend/src/pages/activate.js" /* webpackChunkName: "component---src-pages-activate-js" */),
  "component---src-pages-admin-js": () => import("/root/verifeco/frontend/src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-bc-js": () => import("/root/verifeco/frontend/src/pages/bc.js" /* webpackChunkName: "component---src-pages-bc-js" */),
  "component---src-pages-business-js": () => import("/root/verifeco/frontend/src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-change-password-js": () => import("/root/verifeco/frontend/src/pages/change-password.js" /* webpackChunkName: "component---src-pages-change-password-js" */),
  "component---src-pages-company-js": () => import("/root/verifeco/frontend/src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-create-business-card-js": () => import("/root/verifeco/frontend/src/pages/create-business-card.js" /* webpackChunkName: "component---src-pages-create-business-card-js" */),
  "component---src-pages-email-signature-js": () => import("/root/verifeco/frontend/src/pages/email-signature.js" /* webpackChunkName: "component---src-pages-email-signature-js" */),
  "component---src-pages-faqs-js": () => import("/root/verifeco/frontend/src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("/root/verifeco/frontend/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-january-2020-js": () => import("/root/verifeco/frontend/src/pages/landing-january-2020.js" /* webpackChunkName: "component---src-pages-landing-january-2020-js" */),
  "component---src-pages-login-js": () => import("/root/verifeco/frontend/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-logout-js": () => import("/root/verifeco/frontend/src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-password-reset-js": () => import("/root/verifeco/frontend/src/pages/password-reset.js" /* webpackChunkName: "component---src-pages-password-reset-js" */),
  "component---src-pages-personal-area-js": () => import("/root/verifeco/frontend/src/pages/personal-area.js" /* webpackChunkName: "component---src-pages-personal-area-js" */),
  "component---src-pages-pricing-js": () => import("/root/verifeco/frontend/src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("/root/verifeco/frontend/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-support-js": () => import("/root/verifeco/frontend/src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-verify-your-business-js": () => import("/root/verifeco/frontend/src/pages/verify-your-business.js" /* webpackChunkName: "component---src-pages-verify-your-business-js" */)
}

