import React from 'react'

import DataProvider from './src/components/data-provider'

import AppStore from './src/components/app-store'

export const wrapRootElement = ({ element }) => {
    return (
        <DataProvider>
            <AppStore>{element}</AppStore>
        </DataProvider>
    )
}
