import React from 'react'

import fetch from 'isomorphic-fetch'

import { ApolloProvider } from '@apollo/react-hooks'

import gql from 'graphql-tag'

import { InMemoryCache } from 'apollo-cache-inmemory'

import { ApolloClient } from 'apollo-client'

import { createUploadLink } from 'apollo-upload-client'

const headers = {}

if (typeof localStorage !== 'undefined' && localStorage.token)
    headers.authorization = `Bearer ${localStorage.token}`

const client = new ApolloClient({
    fetch,
    cache: new InMemoryCache().restore({}),
    link: createUploadLink({
        uri: process.env.GATSBY_API_URL,
        headers: headers,
    }),
})

export const CREATE_CARD_FOR_EVENT = gql`
    mutation CreateCardForEvent($cardId: ID, $eventName: String) {
        createCardForEvent(cardId: $cardId, eventName: $eventName) {
            type
            message
        }
    }
`

export const SAVE_BUSINESS_CARD = gql`
    mutation SaveBusinessCard($businessCard: BusinessCardInput!) {
        saveBusinessCard(businessCard: $businessCard) {
            type
            errorPath
            message
            record {
                _id
                firstName
                lastName
                website
                slug
                title
                email
                workCell
                workPhone
                organization
                address
                licenseNumber
                organizationName
                companyLogoId
                personalPhotoId
                avatarUrl
                active
            }
        }
    }
`

export const REGISTER = gql`
    mutation Register($user: UserInput!) {
        register(user: $user) {
            type
            record {
                _id
            }
        }
    }
`

export const LOGIN = gql`
    mutation Login($user: UserInput!) {
        login(user: $user) {
            type
            message
            token
            errorPath
        }
    }
`

export const SINGLE_UPLOAD = gql`
    mutation SingleUpload($file: Upload!) {
        singleUpload(file: $file) {
            _id
            url
            crop {
                x
                y
                width
                height
                unit
            }
        }
    }
`

export const GET_BUSINESS_CARD_BY_SLUG = gql`
    query GetBusinessCardBySlug($slug: String) {
        businessCard(slug: $slug) {
            _id
            firstName
            lastName
            slug
            title
            email
            workCell
            workPhone
            address
            licenseNumber
            organizationName
            avatarUrl
            personalPhotoId
            active
            website
            verified
            eventName
            personalPhoto {
                url
                crop {
                    x
                    y
                    width
                    height
                    unit
                }
            }
            companyLogo {
                url
                crop {
                    x
                    y
                    width
                    height
                    unit
                }
            }
        }
    }
`

export const ACTIVATE_BUSINESS_CARD = gql`
    mutation ActivateBusinessCard($activationCode: String!) {
        activateBusinessCard(activationCode: $activationCode) {
            type
            message
            record {
                _id
                active
                slug
            }
        }
    }
`

export const MY_PROFILE = gql`
    query MyProfile {
        myProfile {
            email
            businessCards {
                _id
                firstName
                lastName
                slug
                title
                email
                workCell
                workPhone
                address
                licenseNumber
                organizationName
                verified
                avatarUrl
                website
                personalPhotoId
                active
                eventName
                personalPhoto {
                    url
                }
            }
        }
    }
`

export const DELETE_BUSINESS_CARD = gql`
    mutation DeleteBusinessCard($id: ID!) {
        deleteBusinessCard(id: $id)
    }
`

export const GET_FILE = gql`
    query GetFile($id: ID!) {
        file(id: $id) {
            url
            crop {
                x
                y
                width
                height
                unit
            }
        }
    }
`

export const SAVE_CROP = gql`
    mutation SaveCrop($fileId: ID!, $crop: CropInput) {
        saveCrop(fileId: $fileId, crop: $crop) {
            url
            crop {
                x
                y
                width
                height
                unit
            }
        }
    }
`

export const SEND_PASSWORD_RESET_LINK = gql`
    mutation SendPasswordResetLink($email: String) {
        sendPasswordResetLink(email: $email) {
            type
            message
        }
    }
`

export const CHANGE_PASSWORD = gql`
    mutation ChangePassword($password: String, $code: String) {
        changePassword(password: $password, code: $code) {
            type
            message
        }
    }
`

export default ({ children }) => {
    return <ApolloProvider client={client}>{children}</ApolloProvider>
}
